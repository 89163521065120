<template>
    <Team />
</template>

<script>
import Team from '@apps/Team'
import pageMeta from '@/mixins/pageMeta'
export default {
    mixins: [pageMeta],
    components: {
        Team
    }
}
</script>