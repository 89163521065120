<template>
    <component 
        :is="viewComponent"
        showPageTitle
        :pageName="pageName" >
        <template #pageFilter>
            <PageFilter
                :model="model"
                :key="pageName"
                onlySearch
                size="large"
                :page_name="pageName"/>
        </template>
        <!--<template #addButton>
            <a-button
                type="primary"
                flaticon
                icon="fi-rr-plus"
                size="large"
                @click="addOrganization('organization')">
                {{ `Добавить организацию` }}
            </a-button>
        </template>-->
    </component>
</template>

<script>
import PageFilter from '@/components/PageFilter'
import eventBus from '@/utils/eventBus'

export default {
    components: {
        PageFilter
    },
    data() {
        return {
            activeTab: 'ours',
            model: 'catalogs.ContractorModel',
            pageName: 'organizationList'
        }
    },
    computed: {
        isMobile() {
            return this.$store.state.isMobile
        },
        viewComponent() {
            if(this.isMobile)
                return () => import(/* webpackMode: "lazy" */'./components/ViewTypes/ViewAccordionTreeMobile.vue')
            return () => import(/* webpackMode: "lazy" */'./components/ViewTypes/ViewAccordionTree.vue')
        }
    },
    methods: {
        addOrganization(organization_type) {
            eventBus.$emit('create_organization', { organization_type })
        }
    }
}
</script>